import { Observable, from } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
//import { AmplifyService } from 'aws-amplify-angular';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(
      private httpClient: HttpClient,
      //private amplifyService: AmplifyService,
    ) {}

  get<T>(path: string, url: string): Observable<T> {
    return this.getHeaders().pipe<T>(
      switchMap<any, Observable<T>>(
        headers => this.httpClient.get<T>(url + path, headers as Object),
      ),
    );
  }

  post<T>(url: string, data: any): Observable<T> {
    return this.getHeaders().pipe<T>(
      switchMap<any, Observable<T>>(
        headers => this.httpClient.post<T>(url, data, headers as Object),
      ),
    );
  }

  putFile<T>(url: string, data: any): Observable<T> {
    return this.getMultiPartHeaders().pipe(
      switchMap<any, Observable<any>>(
        headers => this.httpClient.put(url, data, headers as Object),
      ),
    );
  }

  delete(path: string, url: string) {
    return this.getHeaders().pipe(
      switchMap(
        headers => this.httpClient.delete(url + path, headers as Object),
      ),
    );
  }
  
  private getHeaders(): Observable<any> {
    return this.getIdToken().pipe(map<string, any>(idToken => {
      return {
        headers: new HttpHeaders ({
          'Content-Type': 'application/json',
          'Authorization': idToken,
        }),
      };
    }));
  }

  private getMultiPartHeaders(): Observable<any> {
    return this.getIdToken().pipe(map<string, any>(idToken => {
      return {
        headers: new HttpHeaders ({
          'Content-Type': 'multipart/form-data',
        }),
      };
    }));
  }
  
  private getIdToken(): Observable<string> {
    /*
    return from(this.amplifyService.auth().currentSession())
      .pipe(map<any, string>(currentSession => currentSession.getIdToken().jwtToken));
    */

    return from('test');
  }
}
