import { Component, OnInit } from '@angular/core';

import { AccountRequestInterface } from '@interfaces/AccountRequestInterface';
import { AccountVendingService } from '@services/accountVending.service';

@Component({
  selector: 'app-account-request',
  templateUrl: './account-request.component.html',
  styleUrls: ['./account-request.component.scss']
})
export class AccountRequestComponent implements OnInit {
  
  accountRequest     = {
    BusinessSupport: false,
  } as AccountRequestInterface;

  submitted:         boolean = false;
  showPolicyWarning: boolean = false;
  
  constructor(
    private accountVendingService: AccountVendingService
  ) {}

  ngOnInit() {
  }

  onSubmit(event) {

    if(!this.accountRequest.CookieAndDataPolicyAgreed)
      this.showPolicyWarning = true;
    
    else {
      this.showPolicyWarning = false;

      this.accountVendingService.createAccountRequest(this.accountRequest).subscribe((response: AccountRequestInterface) => {
        this.submitted      = true;
        this.accountRequest = response;
      });
    }
  }
}
