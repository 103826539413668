import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomepageComponent } from '@pages/homepage/homepage.component'
import { AccountRequestComponent } from '@pages/account-request/account-request.component';
import { TermsOfUseComponent } from '@pages/terms-of-use/terms-of-use.component';
import { ServiceCatalogRequestComponent } from '@pages/service-cat-request/service-cat-request.component';


const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'request', component: AccountRequestComponent },
  { path: 'terms-of-use', component: TermsOfUseComponent },
  { path: 'service-cat-request', component: ServiceCatalogRequestComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
