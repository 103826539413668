import { Component, OnInit } from '@angular/core';

import { ServiceCatalogRequestInterface } from '@interfaces/ServiceCatalogRequestInterface';
import { ServiceCatalogService } from '@services/serviceCatalogService.service';

@Component({
  selector: 'app-service-cat-request',
  templateUrl: './service-cat-request.component.html',
  styleUrls: ['./service-cat-request.component.scss']
})
export class ServiceCatalogRequestComponent implements OnInit {
  
  serviceCatalogRequest     = {} as ServiceCatalogRequestInterface;
  submitted: boolean = false;

  constructor(
    private serviceCatalogService: ServiceCatalogService
  ) {}

  ngOnInit() {
  }

  onSubmit(event) {
  }
}
