import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { environment } from '../../environments/environment';

import { AccountRequestInterface } from '@interfaces/AccountRequestInterface';

@Injectable({
  providedIn: 'root',
})
export class AccountVendingService {
  apiUrl: string;

  constructor(
    private apiService: ApiService,
  ) {
    this.apiUrl = 'https://api.avm.' + environment.rootDomain;
  }

  getAll(): Observable<Array<any>> {
    return this.apiService.get<Array<any>>('/vend', this.apiUrl);
  }

  createAccountRequest(request: AccountRequestInterface) {
    return this.apiService.post(this.apiUrl + '/request', request);
  }
}
